<template>
<div class="activity">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="activity-cont">
    <div class="activity-cont-top">
      <Button type="primary" @click="goCreate">+ 创建活动</Button>
      <Button type="primary" @click="goOrganize">主办方管理</Button>
      <Button type="primary" @click="goScore()">积分管理</Button>
      <Button type="primary" @click="goCategory()">活动专题管理</Button>
    </div>
    <div class="search">
      <div class="search-item">
        <p class="label">状态筛选：</p>
        <div class="cont">
          <p :class="status == item.id ? 'active' : ''" v-for="item in statusList" :key="item.id" @click="changeStatus(item.id)">{{ item.name }}</p>
        </div>
      </div>
      <div class="search-item">
        <p class="label">查看角色筛选：</p>
        <div class="cont">
          <p :class="roleId == item.id ? 'active' : ''" v-for="item in roleList" :key="item.id" @click="changeRole(item.id)">{{ item.name }}</p>
        </div>
      </div>
      <div class="search-item">
        <p class="label">查看组织筛选：</p>
        <div class="cont">
          <el-select v-model="siteIds"
                     multiple
                     clearable
                     size="small"
                     style="margin-top: -6px;width: 240px"
                     placeholder="请选择"
                     @change="changeSiteType"
          >
            <el-option
                v-for="item in siteTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              {{item.name}}
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="table">
      <Table border :columns="columns" :data="dataList" :loading="loading"></Table>
    </div>
    <div class="page">
      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
    </div>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js'
import FileSaver from "file-saver";
export default {
  name: "index",
  data(){
    return {
      columns:[
        {
          title: '排序',
          key: 'rank',
          width:80,
        },
        {
          title: 'ID',
          key: 'id',
          width:80,
        },
        {
          title: '活动名称',
          minWidth:300,
          render:(h,params)=>{
            return util.tableColumnTooltip(h,params.row.name);
          }
        },
        {
          title: '开始时间',
          minWidth:150,
          key: 'id',
          render: (h, params) => {
            return h('span', util.timeFormatter(
                    new Date(+params.row.start_time * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '结束时间',
          key: 'id',
          minWidth:150,
          render: (h, params) => {
            return h('span', util.timeFormatter(
                    new Date(+params.row.end_time * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '查看角色',
          minWidth:150,
          key: 'name',
          render: (h, params) => {
            return h('span', this.roleObj[params.row.show_user_role]);
          }
        },
        {
          title: '查看组织',
          minWidth:150,
          key: 'name',
          render: (h, params) => {
            let siteIds = params.row.show_site_type.split(',');
            let names = [];
            siteIds.forEach((item)=>{
              if(item){
                names.push(this.siteObj[item])
              }
            })
            return h('span', names.join(','));
          }
        },
        {
          title: '状态',
          key: 'id',
          minWidth:150,
          render: (h, params) => {
            return h('span', this.statusObj[params.row.status]);
          }
        },
        {
          title: '上架状态',
          width:140,
          render: (h, params) => {
            return h('span', this.verifyStatuses[params.row.verify_status]);
          }
        },
        {
          title: '报名人数',
          key: 'join_user_count',
          width:140,
        },
        {
          title: '操作',
          width:280,
          fixed:"right",
          render: (h, params) => {
            return h('div', [
              h('el-button', {
                props: {
                  type: 'warning',
                  size: 'mini',
                },
                on: {
                  click: () => {
                    this.edit(params.row);
                  }
                }
              }, '编辑'),
              h('el-button', {
                props: {
                  type: 'primary',
                  size: 'mini',
                },
                on: {
                  click: () => {
                    this.goDetail(params.row);
                  }
                }
              }, '报名详情'),
              // params.row.verify_status == 1 ? h('el-option', {
              //   props: {
              //     value:"下架"
              //   },
              //   style: {
              //     color:'red',
              //   },
              //   on: {
              //     click: () => {
              //       this.offLine(params.row);
              //     }
              //   }
              // }, '下架') : h('el-option', {
              //   props: {
              //     value: '上架',
              //   },
              //   on: {
              //     click: () => {
              //       this.onLine(params.row);
              //     }
              //   }
              // }, '上架'),

              h("el-select",{
                props:{
                  transfer: true,//不受表格高度影响,
                  placeholder:this.$t('codekey_manage_more'),
                  size: 'mini',
                  clearable:true,
                  value:""
                },
                style:{
                  width:"75px",
                  marginLeft:"5px"
                },
                on:{
                  change:(event)=>{
                    if(event==="下载二维码"){
                      this.download(params.row)
                    }else if(event==="下架"){
                      this.offLine(params.row);
                    }else if(event==="上架"){
                      this.onLine(params.row);
                    }
                  }
                }
              },[
                h("el-option",{
                  props:{
                    value:"下载二维码"
                  }
                },"下载二维码"),
                params.row.verify_status == 1 ? h('el-option', {
                  props: {
                    value:"下架"
                  },
                  style: {
                    color:'red',
                  },
                }, '下架') : h('el-option', {
                  props: {
                    value: '上架',
                  },
                }, '上架'),
              ])
            ]);
          }
        },
      ],
      dataList:[],
      loading:false,
      total:0,
      page:1,
      pageSize:10,
      status:'-1',
      statusList:[],
      statusObj:{},
      curData:{},
      verifyStatuses:{},
      siteTypeList:[],
      roleList:[],
      roleId:'0',
      siteIds:[],
      roleObj:{},
      siteObj:{},
    }
  },
  created(){
    this.getForm();
    this.getList();
  },
  methods:{
    getForm(){
      this.api.activity.activityForm().then((res)=>{
        this.siteTypeList = [];
        for(let name in res.siteTypes){
          this.siteTypeList.push({
            name:res.siteTypes[name],
            id:name,
            status:false,
            type:'0',
            list:[],
          })
        }
        this.roleList = [];
        for(let name in res.showUserRoles){
          this.roleList.push({
            name:res.showUserRoles[name],
            id:name
          })
        }
        this.roleObj = res.showUserRoles;
        this.siteObj = res.siteTypes;

      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    getList(){  //获取列表数据
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        is_with_apply:1,
        status:this.status,
        site_id:-1,
        user_id:-1,
        show_user_role:this.roleId,
        show_site_type:this.siteIds.join(',')
      };
      this.api.activity.list(params).then((res)=>{
        this.dataList = res.list;
        this.statusObj = res.statuses;
        this.statusList = [{
          id:'-1',
          name:'全部'
        }];
        for(let name in this.statusObj){
          this.statusList.push({
            id:name,
            name:this.statusObj[name]
          })
        }
        this.verifyStatuses = res.verifyStatuses;
        this.total = Number(res.count);
      })
    },
    changeStatus(data){
      this.status = data;
      this.page = 1;
      this.getList();
    },
    changeRole(data){
      this.roleId = data;
      this.page = 1;
      this.getList();
    },
    changeSiteType(){
      this.page = 1;
      this.getList();
    },
    goOrganize(){
      this.$router.push({
        path:'/organize'
      })
    },
    // 去积分管理
    goScore(){
      this.$router.push({
        path:"/points"
      });
    },
    goCreate(){
      this.$router.push({
        path:'/activity/create'
      })
    },
    edit(data){
      this.curData = data;
      this.$router.push({
        path:`/activity/create`,
        query:{
          id:data.id
        }
      })
    },
    onLine(data){
      this.curData = data;
      let params = {
        id:this.curData.id
      };
      this.api.activity.online(params).then((res)=>{
        this.$Message.success('操作成功!');
        this.getList();
      })
    },
    offLine(data){
      this.curData = data;
      let params = {
        id:this.curData.id
      };
      this.api.activity.offline(params).then((res)=>{
        this.$Message.success('操作成功!');
        this.getList();
      })
    },
    goDetail(data){
      this.curData = data;
      this.$router.push({
        path:`/activity/detail`,
        query:{
          id:data.id
        }
      })
    },
    download(data){
      this.curData = data;
      let name = data.qr_url.split('/');
      name = name[name.length -1].split('.');
      name = name[0] + '.' + name[1];

      FileSaver.saveAs(data.qr_url,name);
    },
    goCategory(){
      this.$router.push({
        path:`/activity/category`
      })
    }
  }
}
</script>

<style scoped lang="scss">
.activity{
  margin: 20px;
  font-size: 14px;
  .activity-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
    .activity-cont-top{
      margin-bottom: 20px;
      >button{
        margin-right: 20px;
      }
    }
    .search{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .search-item{
        margin-right: 30px;
        display: flex;
        justify-content: flex-start;
      }
      .label{
        font-weight: bold;
      }
      .cont{
        display: flex;
        justify-content: flex-start;
        >p{
          margin-right: 20px;
          cursor: pointer;
        }
        .active{
          font-weight: bold;
          color: #4A79FF;
        }
      }
    }
    .table{
      margin-top: 20px;
    }
    .page{
      margin-top: 20px;
      text-align: right;
    }
  }
}
</style>
